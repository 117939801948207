import * as React from 'react';

const AppleBadge = () => (
  <svg style={{ width: '140px', height: '80px' }} viewBox="0 0 220 100">
    <g fill="none" fillRule="evenodd" opacity=".5">
      <rect
        style={{ width: '200px', height: '60px' }}
        x=".5"
        y=".5"
        stroke="#FFF"
        rx="10"
      />
      <text
        fill="#FFF"
        fontFamily="MyriadPro-Regular, Myriad Pro"
        fontSize="14"
      >
        <tspan x="43" y="19">
          Download on the
        </tspan>{' '}
        <tspan
          x="43"
          y="45"
          fontFamily="MyriadPro-Semibold, Myriad Pro"
          fontSize="28"
          fontWeight="700"
        >
          App Store
        </tspan>
      </text>
      <path
        fill="#FFF"
        d="M26.689 29.249c3.242 0 5.316 3.137 5.316 3.137s-3.163 1.717-3.163 5.445c0 4.424 3.974 5.711 3.974 5.711s-3.137 7.442-6.659 7.442c-1.98 0-2.112-1.139-4.744-1.139-2.286 0-3.056 1.14-4.797 1.14C13.293 50.984 9 43.73 9 37.83c0-6.128 4.346-8.582 7.07-8.582 2.393 0 3.39 1.408 5.316 1.408 1.622 0 2.898-1.408 5.303-1.408zM26.13 23c.478 3.004-2.246 6.718-5.502 6.598-.479-3.822 3.03-6.396 5.502-6.598z"
      />
    </g>
  </svg>
);

export default AppleBadge;

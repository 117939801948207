import * as React from 'react';

const List = ({ children, className, header }) => (
  <div className={className}>
    {header && header}
    <ul className={`${className}-list`}>
      {React.Children.map(children, child => (
        <li className={`${className}-list-item`}>{child}</li>
      ))}
    </ul>
  </div>
);

export default List;

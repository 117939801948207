import * as React from "react";
import { css } from "@emotion/core";
import { Link } from "@fitplan/lib/components/Link";
import { WebImage } from "@fitplan/lib/components/WebImage";

import { trialBranchLink, imageBaseUrl, localeUrls } from "../../config";
import styled from "@emotion/styled";
import { Button, Language, LanguageText, Pipe, ClearButton, CloseIcon } from "./elements";
import { StaticHeaderStrings, desktopBreakpoint, isEnglish, isSpanish } from "./common";

export interface Props {
    loggedIn: boolean;
    isMenuOpen: boolean;
    strings: StaticHeaderStrings;
    onLogout: (event: any) => void;
    setIsMenuOpen: (value: boolean) => void;
    isSSR: boolean;
}

export const FullScreenMenu: React.SFC<Props> = ({
    strings,
    onLogout,
    loggedIn,
    isMenuOpen,
    setIsMenuOpen,
    isSSR,
}) => {
    return (
        <DropdownContainer isMenuOpen={isMenuOpen}>
            <Link to="/">
                <WebImage
                    src={`${imageBaseUrl}/web/global/logo.png`}
                    transformation={{ height: 80 }}
                    css={css`
                        height: 2.5em;
                        padding-top: 0.25em;
                        flex: 0 0 auto;
                    `}
                />
                <WebImage
                    src={`${imageBaseUrl}/web/global/logo-text.png`}
                    transformation={{ height: 80 }}
                    css={css`
                        height: 2.5em;
                        padding-top: 0.25em;
                        flex: 0 0 auto;
                    `}
                />
            </Link>
            <Spacer />
            <InnerContainer>
                <Link to="/trainers" css={linkCss}>
                    <Menu>{strings.trainers}</Menu>
                </Link>
                <Link to="/plans" css={linkCss}>
                    <Menu>{strings.fitplans}</Menu>
                </Link>
                <Link to="https://blog.fitplanapp.com" css={linkCss}>
                    <Menu>MAGAZINE</Menu>
                </Link>
                <Link to="https://store.fitplanapp.com/" css={linkCss}>
                    <Menu>SHOP</Menu>
                </Link>
                <Link to="/giftcard" css={linkCss}>
                    <GiftMenu>GIFT CARD</GiftMenu>
                </Link>
                {!isSSR && !loggedIn && (
                    <Link to="/login" css={linkCss}><Menu>{strings.login}</Menu></Link>
                )}
                {!isSSR && loggedIn && (
                    <Link to="/account" css={linkCss}><Menu>{strings.account}</Menu></Link>
                )}
                {!isSSR && loggedIn && (
                    <Link to="/logout" css={linkCss}><Menu onClick={onLogout}>{strings.logout}</Menu></Link>
                )}
            </InnerContainer>
            <Spacer />
            {!loggedIn && (
                <Button onClick={() => window.location.href = trialBranchLink}>{strings.startButton}</Button>
            )}
            <Spacer />
            <Language>
                <Link to={localeUrls.en}>
                    <LanguageText selected={isEnglish}>ENG</LanguageText>
                </Link>
                <Pipe>|</Pipe>
                <Link to={localeUrls.es}>
                    <LanguageText selected={isSpanish}>ESP</LanguageText>
                </Link>
            </Language>
            <Spacer />
            <ClearButton
                type="button"
                onClick={() => setIsMenuOpen(false)}
            >
                <CloseIcon />
            </ClearButton>
        </DropdownContainer>
    );
};

const linkCss = css`
    flex: 0 0 auto;
    margin: 0.25em;
`;

const Spacer = styled.div<{grow?: number}>`
    display: inline-block;
    flex: ${props => props.grow || 1} 0 10px;
`

const Menu = styled.span`
    font-family: Barlow, sans-serif;
    text-align: center;
    font-size: 1.5em;
    margin: 0;
    padding: 0.75em;
    letter-spacing: 1.5px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;

    :hover,
    :focus {
        color: #00ffb3;
    }
`;
const GiftMenu = styled(Menu)`
    color: red;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (min-height: 380px) {
        flex-direction: column;
        flex-wrap: no-wrap;
    }

    text-align: center;
    justify-items: center;
`;

const DropdownContainer = styled.div<{isMenuOpen: boolean}>`
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${props => props.isMenuOpen ? "100vh" : "0"};

    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;

    display: flex;
    flex-direction: column;
    z-index: 10000;

    padding: ${props => props.isMenuOpen ? "2em 0" : "0"};
    overflow: hidden;
    box-sizing: border-box;

    @media screen and (min-width: ${desktopBreakpoint}) {
        display: none;
    }

    transition: height 100ms ease-in-out;
`;
import * as React from 'react';
import { css } from "@emotion/core";

const InstagramIcon = () => (
  <svg style={{ width: '34px', height: '34px' }} viewBox="0 0 41 40">
    <g
      fill="#95979A"
      fillRule="evenodd"
      css={css`
        &:hover {
          fill: #00ffb3;
        }
      `}
    >
      <path d="M.333 20c0-11.046 8.955-20 20-20 11.046 0 20 8.954 20 20s-8.954 20-20 20c-11.045 0-20-8.954-20-20zM20.334 9.333c-2.897 0-3.26.013-4.398.065-1.136.052-1.91.232-2.589.495a5.223 5.223 0 0 0-1.89 1.23 5.236 5.236 0 0 0-1.23 1.89c-.265.678-.445 1.453-.496 2.589-.051 1.137-.064 1.501-.064 4.398s.013 3.26.064 4.397c.052 1.136.232 1.911.496 2.59a5.227 5.227 0 0 0 1.23 1.889 5.223 5.223 0 0 0 1.889 1.23c.678.265 1.454.444 2.59.496 1.137.052 1.5.065 4.397.065 2.897 0 3.26-.013 4.397-.065 1.136-.052 1.912-.231 2.59-.495a5.219 5.219 0 0 0 1.889-1.231 5.236 5.236 0 0 0 1.231-1.89c.262-.678.442-1.453.496-2.589.05-1.137.064-1.5.064-4.397 0-2.897-.013-3.26-.064-4.398-.054-1.136-.234-1.91-.496-2.59a5.235 5.235 0 0 0-1.231-1.889 5.213 5.213 0 0 0-1.889-1.23c-.68-.263-1.456-.443-2.591-.495-1.138-.052-1.5-.065-4.398-.065h.003z" />
      <path d="M19.377 11.256h.957c2.848 0 3.186.01 4.31.06 1.04.048 1.605.222 1.981.368.498.194.853.425 1.226.798.373.373.604.729.798 1.227.146.375.32.94.367 1.98.052 1.124.063 1.462.063 4.309 0 2.846-.011 3.184-.063 4.309-.047 1.04-.22 1.604-.367 1.98a3.298 3.298 0 0 1-.798 1.225 3.299 3.299 0 0 1-1.226.798c-.375.146-.94.32-1.98.367-1.125.051-1.463.063-4.31.063-2.849 0-3.186-.012-4.31-.063-1.04-.048-1.605-.221-1.982-.367a3.304 3.304 0 0 1-1.226-.798 3.306 3.306 0 0 1-.799-1.226c-.146-.376-.32-.94-.367-1.98-.051-1.125-.061-1.463-.061-4.311s.01-3.184.061-4.309c.048-1.04.221-1.604.367-1.98.194-.498.425-.854.799-1.227a3.31 3.31 0 0 1 1.226-.798c.376-.147.941-.32 1.981-.368.984-.045 1.365-.058 3.353-.06v.003zm6.651 1.77a1.28 1.28 0 1 0 0 2.561 1.28 1.28 0 0 0 0-2.56zm-5.694 1.496a5.478 5.478 0 1 0 .001 10.956 5.478 5.478 0 0 0 0-10.956z" />
      <path d="M20.334 16.445a3.556 3.556 0 1 1 0 7.11 3.556 3.556 0 0 1 0-7.11z" />
    </g>
  </svg>
);

export default InstagramIcon;

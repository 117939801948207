import * as React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "@fitplan/lib/components/Link";
import { WebImage } from "@fitplan/lib/components/WebImage";

import {
    trialBranchLink,
    imageBaseUrl,
} from "../../config";
import {
    Button,
    ClearButton,
    BurgerIcon,
} from "./elements";
import {
    StaticHeaderStrings,
    desktopBreakpoint,
} from "./common";
import PromoBanner from "./PromoBanner";

export interface Props {
    strings: StaticHeaderStrings;
    loggedIn: boolean;
    setIsMenuOpen: (value: boolean) => void;
    isSSR: boolean;
    hideBanner?: boolean;
}

export const MobileMenu: React.SFC<Props> = ({
    strings,
    loggedIn,
    setIsMenuOpen,
    isSSR,
}) => {
    return (
        <Container>
            <MenuWrapper>
                <Link to="/">
                    <WebImage
                        src={`${imageBaseUrl}/web/global/logo-text.png`}
                        transformation={{ height: 80 }}
                        css={css`
                            height: 1.75em;
                            padding-top: 0.25em;
                            flex: 0 0 auto;
                        `}
                    />
                </Link>
                <InnerContainer>
                    <Spacer />
                    {!isSSR && !loggedIn && (
                        <Button
                            css={css`
                                flex: 0 0 auto;
                                background-image: linear-gradient( #1d222b, #1d222b);
                            `}
                            onClick={() =>
                                (window.location.href = trialBranchLink)
                            }
                        >
                            {strings.startButton}
                        </Button>
                    )}
                    <Spacer />
                </InnerContainer>
                <ClearButton
                    css={css`
                        margin-top: 4px;
                    `}
                    onClick={() => setIsMenuOpen(true)}
                >
                    <BurgerIcon />
                </ClearButton>
            </MenuWrapper>
        </Container>
    );
};

const Spacer = styled.div`
    display: inline-block;
    flex: 1 0 10px;
`;

const MenuWrapper = styled.div`
    display: flex;
    flex: 1 0 100vw;
    box-sizing: border-box;
    padding: 1em;
    align-items: center;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;

    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    justify-items: center;

    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: ${desktopBreakpoint}) {
        display: none;
    }
`;

const InnerContainer = styled.div`
    display: flex;

    flex: 1 0 10px;
    flex-wrap: wrap;
`;

/** @jsx jsx */
import * as React from "react";
import { jsx } from "@emotion/core";
import { SerializedStyles } from "@emotion/core";

type Transformation =
    | string
    | {
          width?: number
          height?: number
          aspectRatio?: [number, number],
      };

interface ImageKitProps {
    src: string;

    // Transformation template name (defined in ImageKit dashboard)
    transformation?: Transformation;
}

export type Props = ImageKitProps & React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
> & {
    className?: string
    css?: SerializedStyles
    style?: React.CSSProperties,
};

export const WebImage: React.FunctionComponent<Props> = ({
    src,
    transformation = {},
    ...props
}) => <img src={getUrl(src, transformation)} {...props} />;

const getTransformationString = (props: Transformation) => {
    if (typeof props === "string") {
        return `&tr=n-${props}`;
    }

    const output: string[] = [];
    if (!props || Object.keys(props).length === 0) {
        return "&tr=none";
    }

    if (props.width) {
        output.push(`w-${props.width}`);
    }
    if (props.height) {
        output.push(`h-${props.height}`);
    }
    if (props.aspectRatio) {
        output.push(`ar-${props.aspectRatio[0]}-${props.aspectRatio[1]}`);
    }

    return `&tr=${output.join(",")}`;
};

const getUrl = (src: string, props: Transformation) => {
    let path = src;
    if (src && src[0] === "/") {
        path = path.slice(1);
    }
    return `${src}?${getTransformationString(props)}`;
};

import * as React from 'react';
import PropTypes from 'prop-types';
import { css } from "@emotion/core";
import {Link} from "@fitplan/lib/components/Link";

import List from '../../components/list/list';
import AppleBadge from './AppleBadge';
import GoogleBadge from './GoogleBadge';
import FacebookIcon from './FacebookIcon';
import InstagramIcon from './InstagramIcon';
import YoutubeIcon from './YoutubeIcon';
import PintrestIcon from './PintrestIcon';
import { localeTextGetter } from '../../utils/l10n';
import { graphql, StaticQuery } from 'gatsby';
import './footer.scss';

const Footer = props => (
  <StaticQuery
    query={graphql`
      query footerQuery {
        webFooter {
          company
          about
          blog
          mealplan
          usefulLinks
          downloadLink
          support
          passwordReset
          privacyPolicy
          terms
          contactBd
          contactPr
        }
        sanitySelectDevice {
          apple
          google
        }
      }
    `}
    render={data => {
      const {
        webFooter: {
          company,
          about,
          blog,
          mealplan,
          usefulLinks,
          support,
          passwordReset,
          privacyPolicy,
          terms,
          contactBd,
          contactPr
        },
        sanitySelectDevice: { apple, google },
      } = localeTextGetter(data);
      return (
        <footer className="footer">
          <div className="footer__logo">
            <img className="footer__logo-img" src={props.logo} alt="Logo" />
          </div>
          <div className="footer__container">
            <div className="footer__links">
              <List className="footer__links-block" header={<h2>{company}</h2>}>
                <Link to="/about">{about}</Link>
                <Link to="https://blog.fitplanapp.com">Magazine</Link>
                <Link to="/contact/bd">{contactBd}</Link>
                <Link to="/contact/pr">{contactPr}</Link>
              </List>
            </div>
            <div className="footer__links">
              <List
                className="footer__links-block"
                header={
                  <h2
                    css={css`
                      @media (max-width: 768px) {
                        margin-top: 25px;
                      }
                    `}
                  >
                    {usefulLinks}
                  </h2>
                }
              >
                {/* <Link to="/select-device">{downloadLink}</Link> */}
                <Link to="https://support.fitplanapp.com">{support}</Link>
                <Link to="/forgot-password">{passwordReset}</Link>
                <Link to="/giftcard/redeem">Redeem Gift Card</Link>
                <Link to="/privacy-policy">{privacyPolicy}</Link>
                <Link to="/terms-of-service">{terms}</Link>
              </List>
            </div>
            <div className="footer__links">
              <List className="footer__links-badge" header={<h2>DOWNLOADS</h2>}>
                <div
                  css={css`
                    @media (max-width: 768px) {
                      display: inline-block;
                    }
                  `}
                >
                  <Link to={apple}>
                    <AppleBadge />
                  </Link>
                  <Link to={google}>
                    <GoogleBadge />
                  </Link>
                </div>
              </List>
            </div>
            <div className="footer__links">
              <List
                className="footer__links-social"
                header={<h2>FOLLOW US</h2>}
              >
                <div
                  css={css`
                    display: inline-block;
                    > * {
                      margin-right: 20px;
                    }
                  `}
                >
                  <Link to="https://www.facebook.com/fitplantech">
                    <FacebookIcon />
                  </Link>
                  <Link to="https://www.instagram.com/fitplan_app/">
                    <InstagramIcon />
                  </Link>
                  <Link to="https://www.youtube.com/channel/UCREG7CKg5rx4Pl-FlplqXrA">
                    <YoutubeIcon />
                  </Link>
                   <Link to="https://www.pinterest.ca/fitplanapp/">
                    <PintrestIcon />
                  </Link>
                </div>
              </List>
            </div>
          </div>
        </footer>
      );
    }}
  />
);

Footer.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default Footer;

export const smallPhoneBreakpoint = "330px";
export const tabletBreakpoint = "860px";
export const desktopBreakpoint = "1250px";

export const isEnglish = !(process.env.GATSBY_L18N_LOCALE === "es");
export const isSpanish = process.env.GATSBY_L18N_LOCALE === "es";

export interface StaticHeaderStrings {
    home: string;
    trainers: string;
    fitplans: string;
    blog: string;
    account: string;
    login: string;
    logout: string;
    events: string;
    startButton: string;
}
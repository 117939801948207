import * as React from "react";
import { css } from "@emotion/core";
import { push, graphql, useStaticQuery } from "gatsby";
import { Link } from "@fitplan/lib/components/Link";
import { WebImage } from "@fitplan/lib/components/WebImage";

import { trialBranchLink, imageBaseUrl, localeUrls } from "../../config";
import styled from "@emotion/styled";
import { Button, Language, LanguageText, Pipe } from "./elements";
import {
    StaticHeaderStrings,
    desktopBreakpoint,
    isEnglish,
    isSpanish,
} from "./common";
import PromoBanner from "./PromoBanner";

export interface Props {
    strings: StaticHeaderStrings;
    onLogout: (event: any) => void;
    loggedIn: boolean;
    isSSR: boolean;
    hideBanner?: boolean;
}

export const DesktopMenu: React.SFC<Props> = ({
    strings,
    onLogout,
    loggedIn,
    isSSR,
}) => {
    return (
        <DesktopMenuContainer>
            <MenuWrapper>
                <Link to="/">
                    <WebImage
                        src={`${imageBaseUrl}/web/global/logo.png`}
                        transformation={{ height: 80 }}
                        css={css`
                            height: 1.75em;
                            padding-top: 0.25em;
                            width: 2em;
                            flex: 0 0 auto;
                        `}
                    />
                </Link>
                <Link to="/">
                    <WebImage
                        src={`${imageBaseUrl}/web/global/logo-text.png`}
                        transformation={{ height: 80 }}
                        css={css`
                            height: 1.75em;
                            padding-top: 0.25em;
                            flex: 0 0 auto;
                        `}
                    />
                </Link>
                <Link to="/trainers" css={linkCss}>
                    <Menu>{strings.trainers}</Menu>
                </Link>
                <Link to="/plans" css={linkCss}>
                    <Menu>{strings.fitplans}</Menu>
                </Link>
                <Link to="https://blog.fitplanapp.com" css={linkCss}>
                    <Menu>MAGAZINE</Menu>
                </Link>
                <Link to="https://store.fitplanapp.com/" css={linkCss}>
                    <Menu>SHOP</Menu>
                </Link>
                <Link to="/giftcard" css={linkCss}>
                    <GiftMenu>GIFT CARD</GiftMenu>
                </Link>
                <Spacer />
                <Language>
                    <Link to={localeUrls.en}>
                        <LanguageText selected={isEnglish}>ENG</LanguageText>
                    </Link>
                    <Pipe>|</Pipe>
                    <Link to={localeUrls.es}>
                        <LanguageText selected={isSpanish}>ESP</LanguageText>
                    </Link>
                </Language>
                {!isSSR && !loggedIn && (
                    <Link to="/login" css={linkCss}>
                        <Menu>{strings.login}</Menu>
                    </Link>
                )}
                {!isSSR && loggedIn && (
                    <Link to="/account" css={linkCss}>
                        <Menu>{strings.account}</Menu>
                    </Link>
                )}
                {!isSSR && loggedIn && (
                    <Link to="/logout" css={linkCss}>
                        <Menu onClick={onLogout}>{strings.logout}</Menu>
                    </Link>
                )}
                {!isSSR && !loggedIn && (
                    <Button
                        css={css`
                            background-image: linear-gradient( #1d222b, #1d222b);
                        `}
                        onClick={() => (window.location.href = trialBranchLink)}
                    >
                        {strings.startButton}
                    </Button>
                )}
            </MenuWrapper>
        </DesktopMenuContainer>
    );
};

const linkCss = css`
    flex: 0 0 auto;
`;

const Spacer = styled.div`
    display: inline-block;
    flex: 1 0 10px;
`;

const MenuWrapper = styled.div`
    display: flex;
    flex: 1 0 100vw;
    box-sizing: border-box;
    padding: 1.5em;
    align-items: center;
`;

const Menu = styled.span`
    font-family: Barlow, sans-serif;
    text-align: center;
    font-size: 1.5em;
    margin: 0;
    padding: 2em 0.75em;
    letter-spacing: 1.5px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;

    :hover,
    :focus {
        color: #00ffb3;
    }
`;

const GiftMenu = styled(Menu)`
    color: white;
`;

export const DesktopMenuContainer = styled.div`
    width: 100%;
    height: 100%;

    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;

    display: none;

    @media screen and (min-width: ${desktopBreakpoint}) {
        display: flex;
        flex-wrap: wrap;
    }
`;

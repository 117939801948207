import * as React from 'react';
import { css } from "@emotion/core";

const YoutubeIcon = () => (
  <svg style={{ width: '34px', height: '34px' }} viewBox="0 0 41 40">
    <g
      fill="#95979A"
      fillRule="evenodd"
      css={css`
        &:hover {
          fill: #00ffb3;
        }
      `}
    >
      <path d="M.667 20c0-11.046 8.967-20 20.029-20 11.061 0 20.028 8.954 20.028 20s-8.967 20-20.028 20C9.634 40 .666 31.046.666 20zm30.453-4.026s-.209-1.502-.848-2.163c-.811-.87-1.72-.873-2.137-.924-2.986-.22-7.464-.22-7.464-.22h-.009s-4.478 0-7.463.22c-.418.05-1.326.055-2.138.924-.64.661-.848 2.163-.848 2.163S10 17.74 10 19.503v1.654c0 1.764.213 3.528.213 3.528s.208 1.502.848 2.164c.812.868 1.878.841 2.352.932 1.707.167 7.254.219 7.254.219s4.482-.007 7.468-.228c.416-.05 1.326-.055 2.137-.923.64-.662.848-2.164.848-2.164s.213-1.764.213-3.528v-1.654c0-1.764-.213-3.529-.213-3.529z" />
      <path d="M18.464 23.16v-6.124l5.763 3.073z" />
    </g>
  </svg>
);

export default YoutubeIcon;

import * as React from 'react';
import { css } from "@emotion/core";

const FacebookIcon = () => (
  <svg style={{ width: '34px', height: '34px' }} viewBox="0 0 40 40">
    <g
      id="facebook"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mobile---375"
        transform="translate(-34.000000, -2301.000000)"
        fill="#95979A"
        css={css`
          &:hover {
            fill: #00ffb3;
          }
        `}
      >
        <g id="Facebook" transform="translate(34.000000, 2301.000000)">
          <path
            d="M0,20 C0,8.95430446 8.95430446,0 20,0 C31.0456955,0 40,8.95430446 40,20 C40,31.0456955 31.0456955,40 20,40 C8.95430446,40 0,31.0456955 0,20 Z M21.4179626,31.0928973 L21.4179626,20.2118413 L24.4216099,20.2118413 L24.819658,16.4621692 L21.4179626,16.4621692 L21.4230658,14.5854222 C21.4230658,13.60745 21.5159862,13.0834301 22.9206368,13.0834301 L24.7983948,13.0834301 L24.7983948,9.33333333 L21.7943222,9.33333333 C18.1859479,9.33333333 16.9158937,11.1523277 16.9158937,14.2113043 L16.9158937,16.4625939 L14.6666667,16.4625939 L14.6666667,20.212266 L16.9158937,20.212266 L16.9158937,31.0928973 L21.4179626,31.0928973 Z"
            id="FB-Default"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default FacebookIcon;

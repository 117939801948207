import * as React from 'react';
import { css } from '@emotion/core';



const PintrestIcon = () => (
  <svg style={{ width: '34px', height: '34px' }} viewBox="0 0 41 40">
    <g
      fill="#95979A"
      fillRule="evenodd"
      css={css`
        &:hover {
          fill: #00ffb3;
        }
      `}
    >
      <path  d="M20.195.429c-11.062 0-20 8.937-20 20 0 8.5 5.25 15.687 12.688 18.625-.188-1.563-.313-4 .062-5.75.375-1.563 2.375-9.938 2.375-9.938s-.625-1.187-.625-3c0-2.75 1.625-4.875 3.625-4.875 1.688 0 2.5 1.25 2.5 2.813 0 1.687-1.062 4.312-1.687 6.687-.5 2 1 3.625 2.937 3.625 3.563 0 6.313-3.75 6.313-9.187 0-4.813-3.438-8.125-8.375-8.125-5.688 0-9 4.25-9 8.687 0 1.75.687 3.563 1.5 4.563.187.187.187.375.125.562-.125.625-.5 2-.563 2.25-.062.375-.312.438-.687.25-2.5-1.187-4.063-4.812-4.063-7.75 0-6.312 4.563-12.125 13.188-12.125 6.937 0 12.312 4.938 12.312 11.563 0 6.875-4.375 12.437-10.375 12.437-2 0-3.937-1.062-4.562-2.312 0 0-1 3.812-1.25 4.75-.438 1.75-1.688 3.937-2.5 5.25 1.875.562 3.875.875 5.937.875 11.063 0 20-8.938 20-20C40.195 9.366 31.258.429 20.195.429z"></path>
      
    </g>
  </svg>
);

export default PintrestIcon;

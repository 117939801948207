import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Cell } from "../ymca/common";
import { desktopBreakpoint } from "./common";

export const tabletBreakpoint = "860px";

export const Button = styled.button`
    color: white;
    font-size: 1em;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1;
    text-align: center;

    padding: 0.5em 1em;
    min-width: 5em;
    
    box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(79deg, #00bd98, #28d95a);
    border: none;
    outline: none;

    border-radius: 1em;

    :hover,
    :focus {
        background-image: linear-gradient(79deg, #28d95a, #00bd98);
        box-shadow: none;
    }

    @media (min-width: ${tabletBreakpoint}) {
        padding: 0.75em;
        border-radius: 1.5em;
    }
`;

const Icon = styled.svg`
    fill: rgba(255, 255, 255, 0.8);
    width: 2em;
    height: 2em;
    transform: scale(1.5);

    :hover,
    :focus {
        fill: white;
    }

    @media (min-width: ${desktopBreakpoint}) {
        display: none;
    }
`;

export const CloseIcon: React.SFC<{ className?: string }> = props => (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </Icon>
);

export const BurgerIcon: React.SFC<{}> = props => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </Icon>
);

export const Pipe = styled.span`
    font-size: 1.5em;
    position: relative;
    top: 0.1em;
    padding: 0 0.25em;
    color: rgba(255, 255, 255, 0.8);
`;

export const Language = styled.div`
    font-size: 1.5em;
    align-self: center;
    margin: 0 1em;
    position: relative;
    top: -0.2em;
`;

export const LanguageText = styled.span<{ selected?: boolean }>`
    color: ${props =>
        props.selected
            ? "rgba(255, 255, 255, 1.0)"
            : "rgba(255, 255, 255, 0.8)"};
`;

export const ClearButton = styled.button`
    box-shadow: none;
    background: none;
    border: none;
    outline: none;

    :hover,
    :focus,
    :active {
        box-shadow: none;
        background: none;
        border: none;
        outline: none;
    }
`;

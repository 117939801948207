import * as React from "react";
import { css } from "@emotion/core";
import { push, graphql, useStaticQuery } from "gatsby";
import { Link } from "@fitplan/lib/components/Link";
import { WebImage } from "@fitplan/lib/components/WebImage";
import { useIsLoggedIn, useAuthContext } from "@fitplan/context/lib-es/auth";

import { locale, trialBranchLink, imageBaseUrl, localeUrls } from "../../config";
import styled from "@emotion/styled";
import { Button, Language, LanguageText, Pipe } from "./elements";
import { StaticHeaderStrings, tabletBreakpoint, desktopBreakpoint } from "./common";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";
import { FullScreenMenu } from "./FullScreenMenu";
import useMount from "react-use/lib/useMount";

export interface Props {
    zIndex?: number;
    isHidden?: boolean;
    hideBanner?: boolean;
    style?: React.CSSProperties;
}

export class StaticHeaderWrapper extends React.Component<Props> {
    render() {
        return <StaticHeader {...this.props} />;
    }
}

interface StaticHeaderQuery {
    globalHeader: StaticHeaderStrings;
}

export const StaticHeader: React.SFC<Props> = ({
    style,
    isHidden,
    zIndex = 10000,
    hideBanner,
}) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isSSR, setIsSSR] = React.useState(true);
    useMount(() => setIsSSR(false))
    const authContext = useAuthContext();
    const onLogout = (event: any) => {
        event.preventDefault();
        authContext.logout();
        push('/');
    };
    const strings = useStaticQuery<StaticHeaderQuery>(graphql`
        query StaticHeader {
            globalHeader {
                home
                trainers
                fitplans
                blog
                account
                login
                logout
                events
                startButton
            }
        }
    `).globalHeader;

    return (
        <>
            <Container
                isHidden={isHidden}
                depth={zIndex}
                style={style}
            >
                <DesktopMenu
                    loggedIn={authContext.loggedIn}
                    onLogout={onLogout}
                    strings={strings}
                    isSSR={isSSR}
                    hideBanner={hideBanner}
                />
                <MobileMenu
                    loggedIn={authContext.loggedIn}
                    strings={strings}
                    setIsMenuOpen={setIsMenuOpen}
                    isSSR={isSSR}
                    hideBanner={hideBanner}
                />
            </Container>
            <FullScreenMenu
                loggedIn={authContext.loggedIn}
                onLogout={onLogout}
                strings={strings}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                isSSR={isSSR}
            />
        </>
    );
};

const Container = styled.div<ContainerProps>`
    background: #000;
    visibility: ${props => props.isHidden ? "hidden" : ""};

    font-family: Barlow, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1;
    font-size: 14px;
    color: white;

    box-sizing: border-box;
    overflow: hidden;
    width: 100vw;
    z-index: ${props => props.depth};

    display: block;
`;


interface ContainerProps {
    depth?: number;
    isHidden?: boolean;
}
